import { Link } from "gatsby";
import React from "react";
import routes from "../../routes";

const DocsSideMenu = () => (
  <section className="page-side-menu">
    <h2>Developers</h2>
    <ul>
      <li>
        <Link to={routes.guides} activeClassName="active">
          Guides
        </Link>
        <ul>
          <li>
            <Link to={routes.gettingStarted} activeClassName="active">
              Getting started
            </Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to={routes.apiReference} activeClassName="active">
          API Reference
        </Link>
      </li>
    </ul>
  </section>
);

export default DocsSideMenu;

import { graphql, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import DocsSideMenu from "../../components/menus/DocsSideMenu";

export default ({
  data: {
    mdx: {
      body,
      frontmatter: { title },
    },
  },
}: PageProps<IData>) => {
  return (
    <Layout>
      <Seo title={title} />

      <div className="container mb-5 markdown-page">
        <div className="col-12">
          <article className="row flex-column-reverse flex-md-row">
            <div className="col-md-4">
              <DocsSideMenu />
            </div>
            <div className="col-md-8">
              <h1 className="text-center h2 mb-3 opacity-75">{title}</h1>
              <div className="markdown-page__content">
                <MDXRenderer>{body}</MDXRenderer>
              </div>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  );
};

interface IData {
  mdx: {
    body: string;
    frontmatter: {
      title: string;
    };
  };
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
      }
    }
  }
`;
